import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Box } from '~/components/layout'
import { useModal } from '~/components/modal'
import ModalConfirm from '~/components/modal-confirm'
import { KindestPaymentForm } from '~/components/payment-form'
import { ChariotPaymentForm } from '~/components/payment-form-chariot'
import { Loading } from '~/components/payment-form/components/loading'
import { Heading, Meta } from '~/components/typography'
import { env } from '~/config/env'
import { routes } from '~/config/routes'
import { PageBySlugQuery } from '~/graphql/page-by-slug'
import { useUtmQuery } from '~/hooks/use-utm-query'
import { FundraisingPageLayout } from '~/layouts/fundraising-page-layout'
import { colors } from '~/styles/colors'
import { Donation } from '~/types'
import {
  DedicationType,
  DonationRecurrenceInterval,
  PageNonprofit,
} from '~/types/graphql'
import { trackConversion } from '~/utils/integrations'

import { BackButton } from '../fundraising-page-checkout/components/back-button'
import { SelectDonation } from '../fundraising-page-checkout/components/select-donation'
import Stepper from '../fundraising-page-checkout/components/stepper'
import {
  ChariotConfirmBanner,
  HeaderColorStripe,
  HeaderContent,
  MetaWrapper,
  StepWrapper,
} from '../fundraising-page-checkout/style'
import { SocialButtons } from '../fundraising-page/components/social-buttons'

type Props = {
  page: PageBySlugQuery['pageBySlug']
  fundraiser?: PageBySlugQuery['pageBySlug']['fundraisers'][0]
}
const ModalThankYou = dynamic(
  () => import('../fundraising-page/components/modal-thank-you')
)

export type SelectDonationStep = Donation & {
  allocationId: string
  showDedication: false
  anonymous: false
  dedication?: {
    firstName: string
    lastName: string
    type: DedicationType
    notification?: {
      email: string
      firstName: string
      lastName: string
      message: string
    }
  }
}

const stripe = loadStripe(env.stripeKey)

export const DonationPage: NextPage<Props> = ({ page, fundraiser }) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)

  const router = useRouter()
  const { query } = useRouter()
  const isUsingChariot = Boolean(query.chariot)
  const thankYouModal = useModal()
  const modalConfirm = useModal()
  const utmQuery = useUtmQuery()

  const {
    title,
    // imageUrl, TODO:
    nonprofit,
    slug,
    googleAdsConversionId,
    googleAdsConversionLabel,
  } = page

  const handleSuccess = (donationAmount: number, orderId: string) => {
    trackConversion(
      'Donation Page',
      donationAmount,
      page.title,
      googleAdsConversionId,
      googleAdsConversionLabel
    )
    setActiveStepIndex(0)
    router.replace(`${routes.donationPage(slug!).as}?donation=${orderId}`)
  }

  const [stripeOptions, setStripeOptions] =
    useState<StripeElementsOptionsMode>()

  useEffect(() => {
    if (sessionStorage && sessionStorage?.formData && activeStepIndex == 1) {
      const storage = JSON.parse(sessionStorage.formData)

      setStripeOptions({
        mode: 'payment',
        currency: 'usd',
        amount: storage.amount,
        setup_future_usage:
          storage.paymentFrequency == DonationRecurrenceInterval.None
            ? undefined
            : 'off_session',
        // INFO: if needed in the future, apply set of payment methods to
        // set which methods should be available
        // Be careful not to forget about BE - this change needs to applied
        // also for payment intent creation
        // INFO^2: idealy get a list of options from BE
        // payment_method_types: ['card', 'us_bank_account'],
        // payment_method_types: [],
        appearance: {
          theme: 'stripe',
        },
      })
    }
  }, [activeStepIndex])

  useEffect(() => {
    if (router.query.donation && !thankYouModal.visible) {
      thankYouModal.show()
    }
    return () => {
      thankYouModal.hide()
    }
  }, [router.query])

  const steps = [
    <SelectDonation
      key={0}
      isUsingChariot
      onSubmit={() => {
        window.scrollTo(0, 0)
        setActiveStepIndex(1)
      }}
      page={page}
    />,
    <>
      {stripeOptions?.amount ? (
        <Elements key={1} stripe={stripe} options={stripeOptions}>
          <KindestPaymentForm
            page={page}
            onSuccess={handleSuccess}
            fundraiserId={fundraiser?.id}
          />
        </Elements>
      ) : (
        <Loading />
      )}
    </>,
    <ChariotPaymentForm
      key={2}
      page={page}
      onSuccess={handleSuccess}
      fundraiserId={fundraiser?.id}
    />,
  ]

  const getActiveStep = () => {
    return isUsingChariot ? steps[2] : steps[activeStepIndex]
  }

  const backButtons = [
    <Link key={0} href={routes.donorPortal.home}>
      <Meta as="a">My Donations</Meta>
    </Link>,
    <BackButton
      key={1}
      query={utmQuery}
      onClick={() => {
        isUsingChariot ? modalConfirm.show() : setActiveStepIndex(0)
      }}
    >
      Back to Choose Your Donation
    </BackButton>,
  ]

  const getBackButtonLabel = () => {
    return backButtons[isUsingChariot ? 1 : activeStepIndex]
  }

  const titles = ['Choose Your Donation', 'Payment Details']

  const getTabTitle = (isUsingChariot?: boolean) => {
    return isUsingChariot ? 'Donor Information' : titles[activeStepIndex]
  }

  return (
    <FundraisingPageLayout
      page={page}
      leftContent={getBackButtonLabel()}
      rightContent={
        <div className="hidden md:flex">
          <SocialButtons slug={slug!} />
        </div>
      }
    >
      <>
        <HeaderColorStripe>
          <HeaderContent>
            <Heading level={3} bold marginBottom={6}>
              {getTabTitle(isUsingChariot)}
            </Heading>
            <Meta regular>{title}</Meta>
            {isUsingChariot ? (
              <ChariotConfirmBanner>
                <Meta>
                  Your donor-advised fund account has been successfully linked!
                </Meta>
              </ChariotConfirmBanner>
            ) : (
              <Box marginTop={12}>
                <Stepper stepsAmount={2} activeStepIndex={activeStepIndex} />
              </Box>
            )}
          </HeaderContent>
        </HeaderColorStripe>
        <StepWrapper>{getActiveStep()}</StepWrapper>
        {activeStepIndex === 1 ? (
          <MetaWrapper>
            <Meta regular color={colors.slateGray} paddingTop="1rem">
              {nonprofit.taxNote}
            </Meta>
          </MetaWrapper>
        ) : (
          <></>
        )}
      </>
      {router.query.donation ? (
        <ModalThankYou
          routeTo={routes.donationPage(slug!).as}
          returnLabel={'Make new donation'}
          orderId={router.query.donation.toString()}
          fundraiserId={fundraiser?.id}
          nonprofit={nonprofit as PageNonprofit}
          slug={slug!}
          {...thankYouModal}
        />
      ) : (
        <></>
      )}
      <ModalConfirm
        heading="Back to Choose Your Donation?"
        message="Are you sure you want to go back to the Choose Your Donation Page?"
        note="Your progress will be lost and any linked funds within the Chariot Donor Advised Fund form will be unlinked."
        submitLabel="Go Back"
        cancelLabel="Cancel"
        onSubmit={() => {
          router.replace(routes.donationPage(slug!).as)
        }}
        danger
        {...modalConfirm}
      />
    </FundraisingPageLayout>
  )
}

export default DonationPage
