import {
  Modal,
  withModal,
  ModalProps,
  ModalActionsProps,
} from '~/components/modal'
import { colors } from '~/styles/colors'

import { Text, Meta } from '../typography'

type Props = ModalProps &
  ModalActionsProps & {
    heading: string
    message: string
    note?: string
  }

const ModalConfirm = ({ heading, message, note, ...modalActions }: Props) => {
  return (
    <Modal heading={heading}>
      <Modal.Body>
        <Text marginBottom={28}>{message}</Text>
        {note && <Meta color={colors.slateGray}>{note}</Meta>}
      </Modal.Body>
      <Modal.Actions {...modalActions} />
    </Modal>
  )
}

export default withModal(ModalConfirm)
